import React from 'react'

import Layout from '../components/Layout'

const AboutPage = () => (
  <Layout>
    <h1>Who we are</h1>
    <h2>Volunteer Staff</h2>
    <ul>
      <li>Mr. Luther B. Tarpeh – Executive Director</li>
      <li>Mr. J. Bazzie Karsor – Deputy Director for Administration</li>
      <li>Mr. Valentino Frank – Deputy Director for Operation</li>
      <li>Mr. Franklin L. Vaye – Executive Secretary</li>
      <li>Mr. Michael J. Dennis – Project Officer</li>
      <li>Mrs. Deddeh Kezele – Finance Officer</li>
      <li>Mr. Augustine Kokolo – General Supervisor</li>
    </ul>
    <h2>Board of Directors</h2>
    <ul>
      <li>Elvis G. Morris</li>
      <li>Viktor Bengtsson</li>
    </ul>
  </Layout>
)

export default AboutPage
